<template lang="vi">
<div>
    <page-header :title="`Tạo mới khảo sát`" :items="breadcrumbs" />
    <div v-if="!loading">
        <b-row>
            <b-col lg="7">
                <b-card>
                    <b-form-group>
                        <label>Tiêu đề<span class="red"> *</span></label>
                        <b-textarea v-model="form.validate.ten" placeholder="Nhập tiêu đề" />
                    </b-form-group>
                    <b-form-group>
                        <label class="mb-1 font-weight-medium">Phân loại <span class="red">*</span></label>
                        <multiselect v-model="form.validate.phanLoai" :options="listPhanLoai" placeholder="Chọn phân loại" :clear-on-select="false" :close-on-select="true" :hide-selected="false" :show-labels="false" :internal-search="false" :multiple="false">
                            <span slot="noResult">Không tìm thấy đơn vị này</span>
                            <span slot="noOptions">Danh sách trống</span>
                            <template slot="option" slot-scope="{ option }">
                                <div class="option__desc">
                                    <span class="option__small">
                                        {{ option }}
                                    </span>
                                </div>
                            </template>
                        </multiselect>
                    </b-form-group>
                    <b-form-group>
                        <label>Thời gian bắt đầu khảo sát <span class="red">*</span></label>
                        <date-picker class="pointer" v-model="form.validate.thoiGianBatDau" value-type="timestamp" type="datetime" :first-day-of-week="1" lang="vi" format="DD-MM-YYYY HH:mm" placeholder="Chọn thời gian bắt đầu khảo sát"></date-picker>
                    </b-form-group>
                    <b-form-group>
                        <label>Thời gian kết thúc khảo sát <span class="red">*</span></label>
                        <date-picker class="pointer" v-model="form.validate.thoiGianKetThuc" value-type="timestamp" type="datetime" :first-day-of-week="1" lang="vi" format="DD-MM-YYYY HH:mm" placeholder="Chọn thời gian kết thúc khảo sát"></date-picker>
                    </b-form-group>
                    <b-form-group>
                        <label>Nội dung <span class="red">*</span></label>
                        <quill-editor class="style-content" :options="editorOption" @change="onEditorChange($event)" />
                    </b-form-group>
                    <b-form-group class="mt-4">
                        <label>Tệp đính kèm</label>
                        <surveys-file :label="'Chọn văn bản'" :value="form.dinhKem" @input="value => form.dinhKem = value" />
                    </b-form-group>
                </b-card>
            </b-col>
            <b-col lg="5">
                <b-card>
                    <b-form-group>
                        <label>Ảnh hiển thị</label>
                        <a-thumbnail label="Chọn ảnh hiển thị" :value="form.validate.preview" @input="value => form.validate.preview = value" />
                    </b-form-group>
                    <div class="style-scroll my-4">
                        <div class="mb-3 style-question" v-for="(item, index) in form.listQA" :key="index">
                            <b-form class="row">
                                <label class="col-3 pr-0 h7">Câu hỏi {{index +1}}</label> 
                                <b-form-select size="sm" v-model="item.type" class="mb-3 col-6 pl-3">
                                    <b-form-select-option value="SingleChoice">Trắc nghiệm một đáp án</b-form-select-option>
                                    <b-form-select-option value="MultiChoice">Trắc nghiệm nhiều đáp án</b-form-select-option>
                                    <b-form-select-option value="ShortAnswer">Câu hỏi trả lời ngắn</b-form-select-option>
                                </b-form-select>
                                <a href="javascript: void(0);" class="text-danger col-3 text-right">
                                    <i v-b-tooltip.hover :title="`Xoá câu hỏi`" class="bx bx-trash style-add" @click.prevent="deleteQuestion(index)"></i>
                                </a>
                            </b-form>
                            <b-form-group>
                                <b-row>
                                    <b-col sm="11">
                                    <b-form-textarea
                                        id="textarea-default"
                                        size="md"
                                        rows="3"
                                        max-rows="3"
                                        placeholder="Nhập câu hỏi ..."
                                        v-model="item.question"
                                    ></b-form-textarea>
                                    </b-col>
                                </b-row>
                            </b-form-group>
                            <b-form-group v-show="item.type != 'ShortAnswer'">
                                <label>Trả lời</label> <a href="javascript: void(0);" v-b-tooltip.hover :title="`Thêm câu trả lời`" class="text-success" @click.prevent="addAnswer(index)"> <i class="bx bx-plus style-add"></i></a>
                                <div v-for="(answer, aindex) in item.listAnswer" :key="aindex" class="row mt-2 ml-0 mr-1">
                                    <b-input v-model="item.listAnswer[aindex].value" placeholder="Nhập câu trả lời" class="col">
                                    </b-input>
                                    <div class="col-1 style-line-height">
                                        <a href="javascript: void(0);" v-b-tooltip.hover :title="`Xoá câu trả lời`" class="text-danger" @click.prevent="deleteAnswer(index,aindex)"> <i class="bx bx-trash"></i></a>
                                    </div>
                                </div>
                            </b-form-group>
                            <b-form-group v-show="item.type == 'SingleChoice'">
                                <label>Trả lời khác</label> <a href="javascript: void(0);" v-b-tooltip.hover :title="`Thêm câu trả lời khác`" class="text-success" @click.prevent="addAnswerOptional(index)"> <i class="bx bx-plus style-add"></i></a>
                                <div v-for="(val, valIndex) in item.answerOptional" class="row mt-2 ml-0 mr-1">
                                    <b-input v-model="val.value" placeholder="Nhập câu trả lời khác" class="col">
                                    </b-input>
                                    <div class="col-1 style-line-height">
                                        <a href="javascript: void(0);" v-b-tooltip.hover :title="`Xoá câu trả lời khác`" class="text-danger" @click.prevent="deleteAnswerOptional(index)"> <i class="bx bx-trash"></i></a>
                                    </div>
                                </div>
                            </b-form-group>
                        </div>
                    </div>
                    <b-button variant="primary" class="small mb-5" @click="addQuestion">Thêm câu hỏi</b-button>
                    <b-form-group class="my-0">
                        <div v-if="showAddErrorMessage" class="alert alert-danger" role="alert">
                            {{addErrorMessage}}
                        </div>
                        <b-button variant="success" class="mr-2" @click.prevent="create">
                            Lưu
                        </b-button>
                        <b-button variant="danger" @click.prevent="toListView">
                            Huỷ
                        </b-button>
                    </b-form-group>
                </b-card>
            </b-col>
        </b-row>
    </div>
</div>
</template>

<script>
import PageHeader from '../../../components/page-header';
import AThumbnail from './partials/a-thumbnail';
import SurveysFile from './partials/surveys-file'
import AFile from './partials/a-file';
import Multiselect from 'vue-multiselect';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/vi';
import {
    httpClient
} from "../../../_utils/httpClient";
import _ from "lodash";
import {
    quillEditor
} from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import Swal from 'sweetalert2';

export default {
    name: 'create',
    components: {
        AThumbnail,
        PageHeader,
        Multiselect,
        DatePicker,
        quillEditor,
        AFile,
        SurveysFile,
    },
    data: function () {
        return {
            breadcrumbs: [{
                text: 'Trang chủ',
                to: '/admin/home',
            }, {
                text: 'Khảo sát',
                to: '/admin/surveys/list',
            }, {
            text: 'Thêm mới',
            to: '#',
            active: true,
        } ],
            editorOption: {
                placeholder: 'Nhập nội dung ở đây....',
            },
            showAddErrorMessage: false,
            addErrorMessage: '',
            listPhanLoai: [],
            loading: false,
            form: {
                validate: {
                    ten: '',
                    phanLoai: '',
                    thoiGianBatDau: '',
                    thoiGianKetThuc: '',
                    noiDung: '',
                    guiThongBao: false,
                    preview: '',
                },
                dinhKem: [],
                listQA: [
                {   id: 0,
                    type: 'SingleChoice', 
                    question: '', 
                    listAnswer: [
                        {id: 0, type: "Normal", value: ""}, 
                    ],
                    answerOptional: [{id: 0, type: 'Optional', value: ''}],
                }
            ]
            },
            options: [{text: 'Có', value: true},{text: 'Không', value: false}],
        };
    },
    watch: {
        'form.validate.preview': {
            handler: function () {
                console.log(this.form.validate.preview);
            },
            deep: true,
        },
    },
    methods: {
        async toListView() {
            await this.$router.push({
                name: 'admin.surveys.list'
            })
        },
        onEditorChange(event) {
            this.form.validate.noiDung = event.html;
        },
        onlySpaces(str) {
            str = str.toString();
            return str.trim().length === 0;
        },
        validator() {
            let mappingErrorName = {
                ten: 'Tiêu đề',
                phanLoai: 'Phân loại',
                thoiGianBatDau: 'Thời gian bắt đầu khảo sát',
                thoiGianKetThuc: 'Thời gian kết thúc khảo sát',
                noiDung: 'Nội dung',
                guiThongBao: 'Gửi thông báo',
                preview: 'Ảnh hiển thị',
            }
            for (let key in this.form.validate) {
                if(key == 'preview') {
                    continue
                }
                if (this.form.validate[key] == null || this.form.validate[key].length < 1 
                    ||  ( key !== 'noiDung' &&  this.onlySpaces(this.form.validate[key]))) 
                {
                    this.showAddErrorMessage = true
                    this.addErrorMessage = mappingErrorName[key] + ` không được trống.`
                    return false
                }
            }
            for (let i = 0; i < this.form.listQA.length; i++) {
                if(this.form.listQA[i].type == "ShortAnswer"){
                    continue;
                }
                for (let j = 0; j < this.form.listQA[i].listAnswer.length; j++) {
                    if (this.form.listQA[i].question !== '' && this.form.listQA[i].listAnswer[j].value == '') {
                        this.showAddErrorMessage = true
                        this.addErrorMessage = `Câu trả lời của câu hỏi ${i + 1} không được trống.`
                        return false;
                    }
                }
                if (this.form.listQA[i].question !== '' && this.form.listQA[i].listAnswer.length < 1) {
                    this.showAddErrorMessage = true
                    this.addErrorMessage = `Câu hỏi ${i + 1} phải có câu trả lời.`
                    return false;
                }
            }
            return true
        },
        async create() {
            await Swal.queue([{
                title: 'Tạo mới khảo sát này',
                confirmButtonText: 'Lưu',
                cancelButtonText: 'Hủy bỏ',
                cancelButtonColor: '#f46a6a',
                confirmButtonColor: '#34c38f',
                showCancelButton: true,
                showconfirmButton: true,
                preConfirm: async () => {
                    this.showAddErrorMessage = false
                    let listQA = _.cloneDeep(this.form.listQA);
                    listQA.forEach((el, index, obj) => {
                        if (el.question === "") {
                            obj.splice(index, 1)
                            return
                        }
                        if(el.type == 'SingleChoice' && el.answerOptional.length>0){
                            if(el.answerOptional[0].value.trim() != "") {
                                el.listAnswer.push(el.answerOptional[0])
                            }
                        }
                        delete el.answerOptional
                        if(el.type == "ShortAnswer"){
                            el.listAnswer = []
                            return
                        }
                        el.listAnswer.forEach((item)=>{
                            item.value = item.value.trim()
                        })
                    })
                    if (!this.validator()) return
                    let payload = {
                        ...this.form.validate,
                        dinhKem: this.form.dinhKem,
                        listQA
                    }
                    let response = await httpClient.post(`doc`, payload)
                    if (response.data.code === 0) {
                        Swal.insertQueueStep({
                                title: 'Tạo mới thành công'
                            }),
                            await this.$router.push({
                                name: 'admin.surveys.list'
                            });
                    } else {
                        this.showAddErrorMessage = true
                        this.addErrorMessage = response.data
                    }
                    return true;
                }

            }, ]);
        },
        addQuestion() {
            this.form.listQA.push({
                id: 0,
                type: 'SingleChoice', 
                question: '', 
                listAnswer: [{ id: 0, type: "Normal", value: ""}],
                answerOptional: [{id: 0, type: "Optional", value: ""}]
            })
        },
        addAnswer(index) {
            this.form.listQA[index].listAnswer.push({id: 0, type: "Normal", value: ""})
        },

        deleteQuestion(index) {
            this.form.listQA.splice(index, 1)
        },

        deleteAnswer(index, aindex) {
            this.form.listQA[index].listAnswer.splice(aindex, 1)
        },
        deleteAnswerOptional(index) {
            this.form.listQA[index].answerOptional.splice(0,1)
        },
        addAnswerOptional(index) {
            if(this.form.listQA[index].answerOptional.length > 0) return
            this.form.listQA[index].answerOptional = [{id: 0, type: 'Optional', value: ''}]
        },
        async getPhanLoai() {
            let response = await httpClient.get(`config`, {
                params: {
                    name: 'phanloaiquyetdinh'
                }
            })
            this.listPhanLoai = response.data.data.map(el => {
                return el.name
            })
        },
    },
    created() {
        this.getPhanLoai();
    },
};
</script>

<style lang="scss" scoped>
.action-column {
    width: 120px;
    text-align: center;
}
</style><style>
.red {
    color: red;
}

.pointer {
    cursor: pointer;
}

.style-line-height {
    display: inline-block;
    line-height: 41px;
}

.style-question {
    border-radius: 4px;
    background-color: rgb(252, 252, 252);
    border: 1px solid #ccc;
    padding: 14px;
}
.style-add {
    font-size: 20px; 
    transform: translatey(3px);
}
.ql-container {
    min-height: 300px;
    max-height: 500px;
    overflow: auto;
}

</style>
